<template>
  <el-drawer
      destroy-on-close
      title="撤销"
      :modal="false"
      size="560px"
      direction="btt"
      :visible.sync="show"
      v-bind="$attrs"
      v-on="$listeners"
      @close="handleCancel"
  >
    <div style="padding: 0 16px">
      <el-form v-loading="loading" ref="formRef" :model="formValue">
        <el-form-item label="撤销原因" prop="comments">
          <el-input type="textarea" v-model="formValue.comments" placeholder="请输入" maxlength="255" rows="4"  show-word-limit />
        </el-form-item>
      </el-form>
    </div>

    <div style="position: fixed;bottom: 16px;left: 50%;transform: translateX(-50%)">
      <el-button style="background-color: #909399;color: white;border-radius: 14px;padding: 15px 40px;border: none"
                 :loading="loading"
                 @click="handleConfirm"
      >撤 销</el-button>
    </div>
  </el-drawer>
</template>

<script>
import { revoke } from '@/api/design';

export default {
  name: 'RevokeModal',
  props: {
    // 是否显示
    visible: {
      type: Boolean,
      default: false
    },
    processInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      formValue: {
        comments: ''
      },
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(visible) {
        this.$emit('update:visible', visible)
      }
    }
  },
  methods: {
    // 确认操作
    handleConfirm() {
      this.$refs.formRef.validate(valid => {
        if(!valid) return;
        const params = {
          ...this.processInfo,
          ...this.formValue,
        }
        this.loading = true
        revoke(params).then(() => {
          this.$message.success('操作成功');
          this.handleCancel();
          this.$emit('success')
        }).finally(() => {
          this.loading = false
        })
      })
    },
    // 取消操作
    handleCancel() {
      this.$refs.formRef.resetFields();
      this.show = false;
    }
  }
}
</script>
