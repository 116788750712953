<template>
  <el-drawer
      destroy-on-close
      title="添加评论"
      :modal="false"
      size="540px"
      direction="btt"
      :visible.sync="show"
      v-bind="$attrs"
      v-on="$listeners"
      @close="handleCancel"
  >
    <div style="padding: 0 16px">
      <el-form
          v-loading="loading"
          ref="formRef"
          :model="formValue"
          :rules="rules"
      >
        <el-form-item label="评论内容" prop="comments" required>
          <el-input
              type="textarea"
              v-model="formValue.comments"
              placeholder="请输入"
              maxlength="255"
              rows="4"
              show-word-limit
          />
        </el-form-item>
        <el-form-item>
          <image-upload v-model="formValue.imageList" />
        </el-form-item>
        <!--      <el-form-item>-->
        <!--        <file-upload v-model="formValue.fileList" />-->
        <!--      </el-form-item>-->
      </el-form>
    </div>

    <div style="position: fixed;bottom: 16px;left: 50%;transform: translateX(-50%)">
      <el-button style="background-color: #909399;color: white;border-radius: 14px;padding: 15px 40px;border: none"
                 :loading="loading"
                 @click="handleConfirm"
      >确 定</el-button>
    </div>
  </el-drawer>
</template>

<script>
import { comments } from "@/api/design";
import ImageUpload from "./ImageUpload";
import FileUpload from "./FileUpload";

export default {
  name: "CommentModal",
  components: { ImageUpload, FileUpload },
  props: {
    // 是否显示
    visible: {
      type: Boolean,
      default: false,
    },
    processInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      formValue: {
        comments: "",
        imageList: [],
        fileList: [],
      },
      rules: {
        comments: [
          { required: true, message: "请填写评论内容", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit("update:visible", visible);
      },
    },
  },
  methods: {
    // 确认操作
    handleConfirm() {
      this.$refs.formRef.validate((valid) => {
        if (!valid) return;
        const { imageList, fileList, ...restParams } = this.formValue;
        const params = {
          ...this.processInfo,
          ...restParams,
          attachments: [...imageList, ...fileList],
        };
        this.loading = true;
        comments(params)
          .then(() => {
            this.$message.success("评论成功");
            this.handleCancel();
            this.$emit("success");
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    // 取消操作
    handleCancel() {
      this.$refs.formRef.resetFields();
      this.formValue = {
        comments: "",
        imageList: [],
        fileList: [],
      };
      this.show = false;
    },
  },
};
</script>
