<template>
  <div >
    <div style="position: fixed;bottom: 16px;left: 50%;transform: translateX(-50%)">
      <div v-if="buttonConfig.length>0" class="util">
        <div style="overflow-y:auto;overflow-y: hidden;padding-bottom: 6px">
          <div style="display: flex">
            <div v-for="item in buttonConfig" :key="item.key">
              <el-button type="text" style="padding:10px 30px;border: none;border-radius: 12px;width: 86px;font-size: 13px" @click="handleClickByType(item.key)" >
                <el-image v-if="item.text == '拒绝'" :src="require('/src/assets/image/refuse-tool.png')" width="60" height="60"/>
                <el-image v-if="item.text == '同意'" :src="require('/src/assets/image/agree.png')" width="60" height="60"/>
                <el-image v-if="item.text == '退回'" :src="require('/src/assets/image/rollback-tool.png')" width="60" height="60"/>
                <el-image v-if="item.text == '转办'" :src="require('/src/assets/image/assignee.png')" width="60" height="60"/>
                <el-image v-if="item.text == '评论'" :src="require('/src/assets/image/comments.png')" width="60" height="60"/>
                <el-image v-if="item.text == '撤销'" :src="require('/src/assets/image/revoke.png')" width="60" height="60"/>
                <div v-if="item.text == '拒绝'" style="margin-top: 6px;color: #F56C6C">{{ item.text }}</div>
                <div v-if="item.text == '同意'" style="margin-top: 6px;color: #6ac33e">{{ item.text }}</div>
                <div v-if="item.text == '退回'" style="margin-top: 6px;color: #facc15">{{ item.text }}</div>
                <div v-if="item.text == '转办'" style="margin-top: 6px;color: #409EFF">{{ item.text }}</div>
                <div v-if="item.text == '评论'" style="margin-top: 6px;color: #606266">{{ item.text }}</div>
                <div v-if="item.text == '撤销'" style="margin-top: 6px;color: #606266">{{ item.text }}</div>
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 同意 -->
    <agree-drawer :visible.sync="modalConfig.agreeVisible" :processInfo="processInfo" />
    <!-- 评论 -->
    <comment-drawer :visible.sync="modalConfig.commentVisible" :processInfo="processInfo" />
    <!-- 委派 -->
    <delegate-modal :visible.sync="modalConfig.delegateVisible" :processInfo="processInfo" />
    <!-- 委派人完成 -->
<!--    <resolve-modal :visible.sync="modalConfig.resolveVisible" :processInfo="processInfo" />-->
    <!-- 拒绝 -->
    <refuse-drawer :visible.sync="modalConfig.refuseVisible" :processInfo="processInfo" />
    <!-- 转办 -->
    <assignee-drawer :visible.sync="modalConfig.assigneeVisible" :processInfo="processInfo" />
    <!-- 退回 -->
    <rollback-drawer :visible.sync="modalConfig.rollbackVisible" :processInfo="processInfo" />
    <!-- 加签 -->
    <add-multi-modal :visible.sync="modalConfig.addMultiVisible" :processInfo="processInfo" />
    <!-- 查到签上的人 -->
    <query-multi-user-modal :visible.sync="modalConfig.queryMultiUserVisible" :processInfo="processInfo" />
    <!-- 撤销 -->
    <revoke-drawer :visible.sync="modalConfig.revokeVisible" :processInfo="processInfo" />
    <!-- 减签 -->
    <delete-multi-modal :visible.sync="modalConfig.deleteMultiVisible" :processInfo="processInfo" />
  </div>
</template>

<script>
import AgreeDrawer from './AgreeDrawer';
import CommentDrawer from './CommentDrawer';
import DelegateModal from './DelegateModal';
import AssigneeDrawer from './AssigneeDrawer';
import RevokeDrawer from './RevokeDrawer';
import RefuseDrawer from './RefuseDrawer';
import RollbackDrawer from './RollbackDrawer';
import AddMultiModal from './AddMultiModal';
import QueryMultiUserModal from './QueryMultiUserModal';
import DeleteMultiModal from './DeleteMultiModal';

// 待我处理
// [同意]agree
// [委派]delegate
// [委派人完成]resolve
// [拒绝]refuse
// [转办]assignee
// [退回]rollback
// [加签]addMulti
// [减签]deleteMulti
// [评论]comments
// [查到签上的人]queryMultiUsersInfo
// const TODO_TASK_KEYS = ['agree', 'delegate', 'resolve', 'refuse', 'assignee', 'rollback', 'addMulti', 'deleteMulti', 'comments', 'queryMultiUsersInfo'];



const TODO_TASK_KEYS = ['agree', 'refuse', 'assignee','comments' , 'rollback', ];
// 我发起的
// [撤销] [评论]
const APPLY_TASK_KEYS = ['revoke', 'comments']

// 关于我的
// [撤销] [评论]
const DONE_TASK_KEYS = [];

// 根据类型 映射对应关系
const BUTTON_KEYS_MAP = {
  todoTask: TODO_TASK_KEYS,
  applyTask: APPLY_TASK_KEYS,
  doneTask: DONE_TASK_KEYS,
};

// 对应按钮映射关系
const OPERATION_BUTTON_MAP = {
  agree: "同意",
  delegate: "委派",
  // resolve: "委派人完成",
  refuse: "拒绝",
  revoke: "撤销",
  assignee: "转办",
  comments: "评论",
  rollback: "退回",
  addMulti: "加签",
  queryMultiUsersInfo: "查到签上的人",
  deleteMulti: "减签",

}

export default {
  props: ["processInfo", "type"],
  components: {
    CommentDrawer,
    DelegateModal,
    AssigneeDrawer,
    RevokeDrawer,
    RefuseDrawer,
    RollbackDrawer,
    AddMultiModal,
    QueryMultiUserModal,
    DeleteMultiModal,
    AgreeDrawer
  },
  name: "ProcessForm",
  data() {
    return {
      visible:false,
      modalConfig: {
        agreeVisible: false,
        commentVisible: false,
        delegateVisible: false,
        assigneeVisible: false,
        // resolveVisible: false,
        refuseVisible: false,
        rollbackVisible: false,
        addMultiVisible: false,
        queryMultiUserVisible: false,
        revokeVisible: false,
        deleteMultiVisible: false
      },
      fromData: {
        comments: "同意",
        processInstanceId: "",
        taskId: "",
      },
      select: [],
    };
  },
  computed: {
    buttonConfig() {
      return Object.keys(OPERATION_BUTTON_MAP).filter(key => BUTTON_KEYS_MAP[this.type].includes(key)).map(key => {
        return {
          text: OPERATION_BUTTON_MAP[key],
          key
        }
      })
    }
  },
  methods: {
    handleClickByType(type) {
      const METHOD_MAP = {
        agree: this.onAgree,
        delegate: this.onDelegateTask,
        resolve: this.onResolveTask,
        refuse: this.onRefuse,
        revoke: this.onRevoke,
        assignee: this.onAssignee,
        rollback: this.onRollback,
        addMulti: this.onAddMulti,
        queryMultiUsersInfo: this.onQueryMultiUsersInfo,
        deleteMulti: this.onDeleteMulti,
        comments: this.onComments,
      }
      METHOD_MAP[type]?.()
    },
    onAgree() {
      this.modalConfig.agreeVisible = true;
    },
    onDelegateTask() {
      this.modalConfig.delegateVisible = true;
    },
    onResolveTask() {
      this.modalConfig.resolveVisible = true
    },
    onRefuse() {
      this.modalConfig.refuseVisible = true
    },
    onRevoke() {
      this.modalConfig.revokeVisible = true;
    },

    onAssignee() {
      this.modalConfig.assigneeVisible = true
    },
    onRollback() {
      this.modalConfig.rollbackVisible = true;
    },
    onAddMulti() {
      this.modalConfig.addMultiVisible = true;
    },
    onQueryMultiUsersInfo() {
      this.modalConfig.queryMultiUserVisible = true;
    },
    onDeleteMulti() {
      this.modalConfig.deleteMultiVisible = true;
    },
    // 添加评论
    onComments() {
      this.modalConfig.commentVisible = true;
    },
  },
};
</script>

<style scoped>
.util{
  width: 88vw;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 15px;
  padding: 8px 12px 0px 12px;
  box-shadow: 0px 0px 10px rgba(99, 99, 99, 0.1);
  .util-item{
    padding: 0;
  }
}

</style>